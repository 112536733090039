<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('vip.gifts/add')" addName="新增商品" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按商品名查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-button size="small" plain @click="detail()" v-if="$hasAccess('vip.gifts/record')">VIP日志</el-button>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="item_name" title="商品名" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.platform||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="sign_type_text" title="支付方式" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="original_cost" title="原价" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="price" title="现价" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" title="首次特惠价" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.first_price==0?'-':row.first_price}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="add_vip_day" title="增加VIP时长(天)" align="center"
				min-width="50px" />
			<vxe-table-column slot="table-item" field="sort_num" title="排序↑" align="center" min-width="30px" />
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="是否有效" align="center" min-width="30px">
				<template v-slot="{row}">
					<span>{{row.status?'是':'否'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="is_spread" title="是否推广" align="center" min-width="30px">
				<template v-slot="{row}">
					<span>{{row.is_spread?'是':'否'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('vip.gifts/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('vip.gifts/del')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="700px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="160px" class="form">
				<el-form-item label="商品名称" prop="item_name">
					<el-input v-model="addDialogFormData.item_name" style="width: 50%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="addDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付方式" prop="sign_type">
					<el-select v-model="addDialogFormData.sign_type" placeholder="请选择支付方式" size="small">
						<el-option label="单次购买" :value="0"></el-option>
						<el-option label="连续包月" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="原价" prop="original_cost">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.original_cost" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="现价" prop="price">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.price" clearable size="small" />
				</el-form-item>
				<el-form-item label="现价购买赠券" prop="coupon_info">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(addDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in addDialogFormData.coupon_info" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(addDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="首次特惠价(0不享受)" prop="first_price">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.first_price" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="首次特惠赠券" prop="first_coupon_info">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onFirstItemAdd(addDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in addDialogFormData.first_coupon_info" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onFirstItemDel(addDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="增加VIP时长(天)" prop="add_vip_day">
					<el-input-number :precision="0" :min="0" :step="30" v-model="addDialogFormData.add_vip_day"
						clearable size="small" />
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="addDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="addDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="addDialogFormData.is_spread" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
				<el-form-item label="是否可领神券" prop="has_coupon">
					<el-switch v-model="addDialogFormData.has_coupon" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('vip.gifts/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="160px" class="form">
				<el-form-item label="商品名称" prop="item_name">
					<el-input v-model="editDialogFormData.item_name" style="width: 60%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付方式" prop="sign_type">
					<el-select v-model="editDialogFormData.sign_type" placeholder="请选择支付方式" size="small">
						<el-option label="单次购买" :value="0"></el-option>
						<el-option label="连续包月" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="原价" prop="original_cost">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.original_cost" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="现价" prop="price">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.price" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="现价购买赠券" prop="coupon_info">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(editDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in editDialogFormData.coupon_info" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(editDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="首次特惠价(0不享受)" prop="first_price">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.first_price" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="首次特惠赠券" prop="first_coupon_info">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onFirstItemAdd(editDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in editDialogFormData.first_coupon_info" class="coupon-item">
							<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券"
								clearable>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
									:value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onFirstItemDel(editDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="增加VIP时长(天)" prop="add_vip_day">
					<el-input-number :precision="0" :min="0" :step="30" v-model="editDialogFormData.add_vip_day"
						clearable size="small" />
				</el-form-item>
				<el-form-item label="排序(小排前)" prop="sort_num">
					<el-input-number :precision="0" :min="0" v-model="editDialogFormData.sort_num" clearable
						size="small" />
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="editDialogFormData.is_spread" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
				<el-form-item label="是否可领神券" prop="has_coupon">
					<el-switch v-model="editDialogFormData.has_coupon" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					v-if="$hasAccess('vip.gifts/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'gifts-index',
		components: {
			Tables,
		},
		data() {
			return {
				tableName: 'VIP',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				couponList: [],
				searchFormData: {},
				addDialogShow: false, // 新增
				addDialogFormData: {
					coupon_info: [],
					first_coupon_info: []
				},
				rules: {
					item_name: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					sign_type: [{
						required: true,
						message: '请选择支付方式',
						trigger: 'change'
					}],
					original_cost: [{
						required: true,
						message: '请输入原价',
						trigger: 'change'
					}],
					price: [{
						required: true,
						message: '请输入现价',
						trigger: 'change'
					}],
					first_price: [{
						required: true,
						message: '请输入首次特惠价',
						trigger: 'change'
					}],
					sort_num: [{
						required: true,
						message: '请填写排序',
						trigger: 'change'
					}],
					add_vip_day: [{
						required: true,
						message: '请输入增加VIP时长',
						trigger: 'change'
					}]
				},
				editDialogShow: false, // 编辑
				editDialogFormData: {
					coupon_info: [],
					first_coupon_info: []
				},
				editNeedData: ['id', 'item_name']
			}
		},
		computed: {
			viewImgList() {
				return function(row) {
					return [row.url]
				}
			}
		},
		methods: {
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				let searchParams = {
					_keywords: this.searchFormData.key
				};
				this.isLoading = true
				this.$api.Vip.GiftsIndex(params, searchParams).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			clearSearch() {
				this.searchFormData = {
					key: '',
				}
			},
			// 重置表单
			resetFormData() {
				this.addDialogFormData = {
					coupon_info: [],
					first_coupon_info: []
				};
				this.editDialogFormData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
				this.getCouponList();
			},
			addOne(row) { //添加商品
				this.resetFormData();
				this.addDialogShow = true;
			},
			editOne(row) { //编辑商品
				this.resetFormData();
				this.editDialogShow = true;
				this.editDialogFormData = row;
			},
			deleteOne(row) { //删除商品
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				this.$api.Vip.GiftsDel(params).then(() => {
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				});
			},
			closeDialog() { // 关闭弹窗
				this.addDialogShow = false
				this.editDialogShow = false
			},
			saveAdd() { // 新增保存
				if (!this.addDialogFormData.item_name) {
					this.$refs.addForm.validateField('item_name');
					return;
				}
				const form = {
					item_name: this.addDialogFormData.item_name,
					sign_type: this.addDialogFormData.sign_type,
					original_cost: this.addDialogFormData.original_cost,
					price: this.addDialogFormData.price,
					first_price: this.addDialogFormData.first_price,
					add_vip_day: this.addDialogFormData.add_vip_day,
					sort_num: this.addDialogFormData.sort_num,
					coupon_info: this.addDialogFormData.coupon_info,
					first_coupon_info: this.addDialogFormData.first_coupon_info,
					status: this.addDialogFormData.status,
					is_spread: this.addDialogFormData.is_spread,
					platform: this.addDialogFormData.platform,
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				this.$api.Vip.GiftsAdd(params).then(() => {
					this.$notify({
						title: "成功",
						message: "添加成功",
						type: "success",
					});
					this.closeDialog();
					this.$refs.xTable.refreshTable()
				});
			},
			// 编辑保存
			saveEdit() {
				if (!this.editDialogFormData.item_name) {
					this.$refs.editForm.validateField('item_name');
					return;
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(this.editDialogFormData)
				}
				this.$api.Vip.GiftsEdit(params).then(() => {
					this.$notify({
						title: '成功',
						message: '编辑成功',
						type: 'success'
					})
					this.closeDialog()
					this.$refs.xTable.refreshTable()
				});
			},
			// 获取优惠券信息
			async getCouponList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Market.GetCouponList(params, {});
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
			},
			//增加
			onItemAdd: function(item) {
				item.coupon_info.push({
					"coupon": null,
					"num": 1
				});
			},
			//删除
			onItemDel: function(item, idx) {
				item.coupon_info.splice(idx, 1)
			},
			//增加
			onFirstItemAdd: function(item) {
				item.first_coupon_info.push({
					"coupon": null,
					"num": 1
				});
			},
			//删除
			onFirstItemDel: function(item, idx) {
				item.first_coupon_info.splice(idx, 1)
			},
			// VIP日志
			detail() {
				this.$router.push({
					name: "vip-record-list",
					query: {},
				});
			},
		}
	}
</script>
<style>
</style>
